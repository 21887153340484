<template>
  <div>
    <template v-if="status !== true">
      <v-alert
        text
        type="warning"
        class="mb-0 text-left"
        :icon="status == 'finished' ? 'mdi-calendar-remove' : undefined"
      >
        <b>O evento {{ party.name }} já terminou</b>, selecione outra data para ver os
        ingressos disponíveis.
      </v-alert>
    </template>
    <div v-else class="text-center my-8">
      <template v-if="salesPending">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1">
            mdi-clock-start
          </v-icon>
        </v-avatar>
        <p class="text--disabled mt-2 mb-0">
          As vendas ainda não começaram, volte mais tarde.
        </p>
      </template>
      <template v-else-if="!ticketOffice">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1">
            mdi-ticket-confirmation
          </v-icon>
        </v-avatar>
        <p class="text--disabled mt-2 mb-0">
          Não há ingressos disponíveis para esse evento
        </p>
      </template>
      <template v-else>
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1">mdi-ticket</v-icon>
        </v-avatar>
        <p class="text--disabled mt-2 mb-2">
          Ingressos disponíveis na bilheteria.
        </p>
        <small class="text--disabled d-block">
          Sujeito à disponibilidade no local
        </small>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PartyStatus from "@/utils/partyStatus";

export default {
  name: "NoTicketGroupAvailable",
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketOffice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    salesPending() {
      if (!this.party?.onlineSaleStartsAt) return false;
      if (moment().isBefore(this.party.onlineSaleStartsAt))
        return this.party?.onlineSaleStartsAt;
      return false;
    },
    status() {
      const party = this.party;
      if (!party) return false;
      const status = new PartyStatus(party);
      return status.isActive || status.status;
    },
  },
};
</script>
