<template>
  <v-dialog
    v-model="show"
    max-width="450"
    @click:outside="cancel"
    content-class="rounded-xl"
  >
    <v-card class="pa-4 px-6 d-flex flex-column gap-8" rounded="xl">
      <h5 class="mb-0 px-2">
        {{ ticketBlock?.prePurchaseAlert?.title }}
      </h5>
      <div class="text-15 px-2">
        <p
          v-for="line in (ticketBlock?.prePurchaseAlert?.message || '').trim().split(
            '\n'
          )"
          class="mb-2"
          style="min-height: 1.2em"
        >
          {{ line }}
        </p>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn text @click="cancel">Cancelar</v-btn>
        <v-btn color="primary" @click="confirm">Continuar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      ticketBlock: null,
      resolve: null,
    };
  },
  methods: {
    async open(ticketBlock) {
      console.log(ticketBlock);
      return new Promise((resolve) => {
        this.ticketBlock = ticketBlock;
        this.show = true;
        this.resolve = resolve;
      });
    },
    close() {
      this.show = false;
      this.resolve = null;
      this.ticketBlock = null;
    },
    confirm() {
      this.resolve(true);
      this.close();
    },
    cancel() {
      this.resolve(false);
      this.close();
    },
  },
};
</script>
